* {
    margin: 0;
    box-sizing: border-box;
}

html {
    height: 100%;
}

#root {
    height: 100%;
}

body {
    line-height: 1.7;
    height: 100%;
}

.middle {
    display: flex;
    flex-grow: 1;
}

.main {
    flex: 1;

    display: flex;
    justify-content: center;
    padding-top: 6rem;
}

.header,
.footer {
    padding: 3rem;
}
